import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import UserInvite from './pages/user';
import DistributorInvite from './pages/distributor';

function App() {
  const vw = window.innerWidth > 420 ? 420 : window.innerWidth;
  return (
      <div className="App" style={{ position: 'relative', width: '100%', maxWidth: vw + 'px', margin: '0 auto' }}>
        <Router>
          <Routes>
            {/*<Route path="/" element={ <UserInvite /> } />*/}
            <Route path="user/invite/:agentId/:activityId/:inviterId" element={ <UserInvite /> } />
            <Route path="distributor/invite/:agentId/:activityId/:inviterId" element={ <DistributorInvite /> } />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
