import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import './index.css';

interface ToastProps {
    className?: string;
    content: string;
    time?: number;
}
const Toast = (props: ToastProps) => {
    const [visiable, setVisiable] = useState(true);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setVisiable(false);
            ReactDOM.unmountComponentAtNode(document.querySelector('#toast')!)
        }, props.time);
        return () => {
            clearTimeout(timerId);
        };
    }, [props.time]);

    return (
        <div
            className={classNames(props.className, `toast__mask`, {
                [`toast__toast--show`]: visiable,
            })}
        >
            <div className={`toast__info`}>{props.content}</div>
        </div>
    );
};
export const ShowToast = (props: ToastProps) => {
    return ReactDOM.render(
        <Toast content={props.content} time={props.time || 3000} />,
        document.querySelector('#toast')
    );
};
