import React from 'react';
import { Button, Input } from 'antd';
import { get, post } from '../../utils/request';
import { ShowToast } from '../../components/toast';
import './index.css';

type DistributorInviteState = {
    phone: string,
    verifyCode: string,
    inviterId: number,
    activityId: number,
    agentId: number,
    inviter: '',
    gains: string[],
    startDate: string,
    endDate: string,
    tips: string,
    count: number,
    oldCount: number,
    timer?: NodeJS.Timeout,
    getCode: boolean,
    neg: any,
}

class DistributorInvite extends React.Component<any, DistributorInviteState> {

    constructor(props: any) {
        super(props);
        this.state = {
            phone: '',
            verifyCode: '',
            inviterId: 0,
            agentId: 0,
            activityId: 0,
            gains: [],
            inviter: '',
            startDate: '',
            endDate: '',
            tips: '获取验证码',
            count: 60,
            oldCount: 60,
            timer: undefined,
            getCode: true,
            // @ts-ignore
            neg: createNEGuardian({ appId: 'YD00123882642571', timeout: 10000 })
        };
    }

    componentDidMount() {
        const paramList = window.location.pathname.split('/');
        // const paramList = window.location.hash.split('/');
        paramList.splice(0, 1)
        if (paramList.length === 5) {
            this.setState({
                agentId: parseInt(paramList[2]),
                activityId: parseInt(paramList[3]),
                inviterId: parseInt(paramList[4]),
            });
            this.getReward(parseInt(paramList[2]), parseInt(paramList[3]), parseInt(paramList[4]));
        }
    }

    componentWillUnmount() {
        if (this.state.timer)
            clearInterval(this.state.timer);
    }

    private async getHtprotectTokenAction() {
        if (!this.state.neg) return '';
        return this.state.neg.getToken().then((result: any) => {
            if (result.code === 200) { // 正常情况
                return result.token;
            }
            if (result.code === 201) { // 离线模式
                return result.token;
            }
            return '';
        }).catch(() => {
            return '';
        })
    }

    // 获取验证码
    private async getCode() {
        const { phone, oldCount } = this.state;
        if (phone.length === 0 || !/^1[3456789]\d{9}$/.test(phone))
            return ShowToast({ content: '请输入正确的手机号' });
        const riskCheckToken = await this.getHtprotectTokenAction();
        if (riskCheckToken.length === 0) return ShowToast({ content: '发送失败' });
        this.sendVerifyCodeApi(phone, riskCheckToken);
        this.setState({
            timer: setInterval(() => {
                this.computingTime();
            }, 1000),
            count: oldCount,
            getCode: false,
        });
    }

    // 计时
    private computingTime() {
        const { count, timer, oldCount } = this.state;
        let _count: number = count;
        if (_count > 0) {
            _count--;
            this.setState({ count: _count, tips: `${ _count }秒后重发` });
        } else {
            if (timer) clearInterval(timer);
            this.setState({ count: oldCount, tips: '重新发送', timer: undefined, getCode: true });
        }
    }

    onPhoneChange = (e: any) => {
        this.setState({ phone: e.target.value.toString().trim() });
    };

    onCodeChange = (e: any) => {
        this.setState({ verifyCode: e.target.value.toString().trim() });
    };

    handleReceive() {
        const { phone, verifyCode } = this.state;
        if (phone.length === 0 || !/^1[3456789]\d{9}$/.test(phone)) {
            return ShowToast({ content: '请输入正确的手机号' });
        }
        if (verifyCode.length === 0) {
            return ShowToast({ content: '请输入验证码' });
        }
        this.sendReceiveApi();
    }

    sendVerifyCodeApi(phone: string, riskCheckToken: string) {
        post('/user/delivery/marketing/distributor/invite/reward/sms', { 'phone': phone, 'riskCheckToken': riskCheckToken }).then(() => {
        }).catch((err) => {
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    sendReceiveApi() {
        const { phone, verifyCode, inviterId, activityId } = this.state;
        post('/user/delivery/marketing/distributor/invite/reward/sms/verify',
            { 'phone': phone, 'verifyCode': verifyCode, 'inviterId': inviterId, 'activityId': activityId }).then(() => {
            ShowToast({ content: '请登录橙子校园APP注册配送员', time: 5000 });
        }).catch((err) => {
            console.log(err);
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    getReward(agentId: number, activityId: number, inviterId: number) {
        get(`/user/delivery/marketing/distributor/invite/reward/config/new/distributor`, { 'agentId': agentId, inviterId: inviterId }).then((response: any) => {
            const { data } = response;
            this.setState({
                gains: data['gains'] ?? [],
                inviter: data['inviter'] ?? '',
                startDate: data['startDate'] ?? '',
                endDate: data['endDate'] ?? '',
            });
        }).catch((err) => {
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    render() {
        const { phone, verifyCode, tips, getCode, count, oldCount, gains, inviter, startDate, endDate } = this.state;
        return (
            <div className="distributor_invite">
                <div className='distributor_invite_header_box'>
                    <div>
                        <div style={{fontSize: '18px'}}>我是：{ inviter[0] ?? '' }同学</div>
                        <div style={{fontSize: '24px', fontWeight: 'bold'}}>邀你参与全民抢单</div>
                        <div style={{fontSize: '24px', fontWeight: 'bold'}}> 不出校园也能赚钱</div>
                        <div style={{fontSize: '16px', color: '#694818'}}>足不出校 / 自由抢单 / 想赚就赚</div>
                    </div>
                    <div className='distributor_invite_header_img' />
                    <div className='distributor_invite_reward_info_bg1' />
                    <div className='distributor_invite_reward_info_bg2'>
                        <div className='distributor_invite_reward_info_bg2_top'>注册并完成抢单 即可获得</div>
                        <div className='distributor_invite_reward_info_bg2_describe'>
                            { gains.length >= 2 ? `${gains[0]}+${gains[1]}` : gains.length === 1 ? `海量抢单收益+${gains[0]}`: '配送专属特权+海量抢单收益' }
                        </div>
                    </div>
                    <div className='distributor_invite_reward_info_bg3'>
                        { startDate.length>0 && endDate.length>0 && <div className='distributor_invite_reward_info_bg3_time'>
                            活动时间：{startDate.replace(/-/g, ".")}-{endDate.replace(/-/g, ".")}
                        </div> }
                    </div>
                </div>
                <div className='distributor_invite_register_box'>
                    <div style={{ textAlign: 'left', fontSize: '20px' }}>注册获取参与资格</div>
                    <div className="distributor_invite_input_box">
                        <div>
                            <span style={{ fontSize: '16px' }}>手机号码</span>
                            <Input
                                className="distributor_invite_input"
                                value={ phone }
                                maxLength={ 11 }
                                onChange={ this.onPhoneChange }
                                placeholder="请填写"
                            />
                        </div>
                    </div>
                    <div className="distributor_invite_input_box">
                        <div>
                            <span style={{ fontSize: '16px' }}>验证码</span>
                            <Input
                                className="distributor_invite_input"
                                value={ verifyCode }
                                maxLength={ 11 }
                                placeholder="请填写"
                                onChange={ this.onCodeChange }
                            />
                        </div>
                        <span
                            onClick={ getCode ? this.getCode.bind(this) : undefined }
                            className={ `${ count === oldCount ? 'distributor_invite_get-code-begin' : 'distributor_invite_get-code-after' }` }
                        >{ tips }</span>
                    </div>
                    <Button
                        className="distributor_invite_button"
                        style={{ backgroundImage: verifyCode.length > 0 && phone.length === 11 ?
                                'linear-gradient(to right, #FFC718, #FFA11A)':
                                'linear-gradient(to right, #DEDEDE, #DEDEDE)' }}
                        onClick={ this.handleReceive.bind(this) }
                    >立即领取</Button>
                </div>
                <div className="toast_box">
                    <div id="toast" />
                </div>
            </div>
        );
    }
}

export default DistributorInvite;
