import axios from 'axios';

const Request = axios.create({
    //请求头默认配置
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
    baseURL: 'https://api.manage.52czxy.com',
    // baseURL: 'http://172.18.0.67:8000',
});

/**
 * http request 拦截器
 */
Request.interceptors.request.use((config: any) => {
    config.data = JSON.stringify(config.data);
    config.headers['api-version'] = 202402;
    return config;
}, (error: any) => {
    return Promise.reject(error);
});

/**
 * http response 拦截器
 */
Request.interceptors.response.use((response: any) => {
    return response;
}, (error: any) => {
    return error;
});

/**
 * 封装get方法
 * @param url 请求url
 * @param params 请求参数
 */
export function get(url: string, params = {}) {
    return new Promise((resolve, reject) => {
        Request.get(url, { params: params }).then((response: any) => {
            if (response.data.code !== 0 && response.data.code !== 200) return reject(response.data);
            resolve(response.data);
        }).catch((error: any) => {
            reject(error);
        });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 */
export function post(url: string, data: {} | undefined) {
    return new Promise((resolve, reject) => {
        Request.post(url, data).then((response: any) => {
            if (response.data.code !== 0 && response.data.code !== 200) return reject(response.data);
            resolve(response.data);
        }, (err: any) => {
            reject(err);
        });
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 */
export function patch(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        Request.patch(url, data).then((response: any) => {
            if (response.data.code !== 0 && response.data.code !== 200) return reject(response.data);
            resolve(response.data);
        }, (err: any) => {
            msag(err);
            reject(err);
        });
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 */
export function put(url: string, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then((response: any) => {
            if (response.data.code !== 0 && response.data.code !== 200) return reject(response.data);
            resolve(response.data);
        }, (err: any) => {
            msag(err);
            reject(err);
        });
    });
}

function msag(err: any) {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                alert(err.response.data.error.details);
                break;
            case 401:
                alert('未授权，请登录');
                break;
            case 403:
                alert('拒绝访问');
                break;
            case 404:
                alert('请求地址出错');
                break;
            case 408:
                alert('请求超时');
                break;
            case 500:
                alert('服务器内部错误');
                break;
            case 501:
                alert('服务未实现');
                break;
            case 502:
                alert('网关错误');
                break;
            case 503:
                alert('服务不可用');
                break;
            case 504:
                alert('网关超时');
                break;
            case 505:
                alert('HTTP版本不受支持');
                break;
            default:
        }
    }
}

export default Request;
