import React from 'react';
import { Input } from 'antd';
import { get, post } from '../../utils/request';
import { ShowToast } from '../../components/toast';
import './index.css';

type UserInviteState = {
    phone: string,
    verifyCode: string,
    inviterId: number,
    activityId: number,
    agentId: number,
    rewardType: number,
    reward: string,
    tips: string,
    count: number,
    oldCount: number,
    timer?: NodeJS.Timeout,
    getCode: boolean,
    neg: any,
}

class UserInvite extends React.Component<any, UserInviteState> {

    constructor(props: any) {
        super(props);
        this.state = {
            phone: '',
            verifyCode: '',
            inviterId: 0,
            agentId: 0,
            activityId: 0,
            rewardType: 0,
            reward: '0',
            tips: '获取验证码',
            count: 60,
            oldCount: 60,
            timer: undefined,
            getCode: true,
            // @ts-ignore
            neg: createNEGuardian({ appId: 'YD00123882642571', timeout: 10000 })
        };
    }

    componentDidMount() {
        const paramList = window.location.pathname.split('/');
        // const paramList = window.location.hash.split('/');
        paramList.splice(0, 1)
        if (paramList.length === 5) {
            this.setState({
                agentId: parseInt(paramList[2]),
                activityId: parseInt(paramList[3]),
                inviterId: parseInt(paramList[4]),
            });
            this.getReward(parseInt(paramList[2]), parseInt(paramList[3]));
        }
    }

    componentWillUnmount() {
        if (this.state.timer) clearInterval(this.state.timer);
    }

    private async getHtprotectTokenAction() {
        if (!this.state.neg) return '';
        return this.state.neg.getToken().then((result: any) => {
            if (result.code === 200) { // 正常情况
                return result.token;
            }
            if (result.code === 201) { // 离线模式
                return result.token;
            }
            return '';
        }).catch(() => {
            return '';
        })
    }

    // 获取验证码
    private async getCode() {
        const { phone, oldCount } = this.state;
        if (phone.length === 0 || !/^1[3456789]\d{9}$/.test(phone))
            return ShowToast({ content: '请输入正确的手机号' });
        const riskCheckToken = await this.getHtprotectTokenAction();
        if (riskCheckToken.length === 0) return ShowToast({ content: '发送失败' });
        this.sendVerifyCodeApi(phone, riskCheckToken);
        this.setState({
            timer: setInterval(() => {
                this.computingTime();
            }, 1000),
            count: oldCount,
            getCode: false,
        });
    }

    // 计时
    private computingTime() {
        const { count, timer, oldCount } = this.state;
        let _count: number = count;
        if (_count > 0) {
            _count--;
            this.setState({ count: _count, tips: `${ _count }秒后重发` });
        } else {
            if (timer) clearInterval(timer);
            this.setState({ count: oldCount, tips: '重新发送', timer: undefined, getCode: true });
        }
    }

    onPhoneChange = (e: any) => {
        this.setState({ phone: e.target.value.toString().trim() });
    };

    onCodeChange = (e: any) => {
        this.setState({ verifyCode: e.target.value.toString().trim() });
    };

    handleReceive() {
        const { phone, verifyCode } = this.state;
        if (phone.length === 0 || !/^1[3456789]\d{9}$/.test(phone)) {
            return ShowToast({ content: '请输入正确的手机号' });
        }
        if (verifyCode.length === 0) {
            return ShowToast({ content: '请输入验证码' });
        }
        this.sendReceiveApi();
    }

    sendVerifyCodeApi(phone: string, riskCheckToken: string) {
        post('/user/marketing/user/invite/reward/sms', { 'phone': phone, 'riskCheckToken': riskCheckToken }).then(() => {
        }).catch((err) => {
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    sendReceiveApi() {
        const { phone, verifyCode, inviterId, activityId } = this.state;
        post('/user/marketing/user/invite/reward/sms/verify',
            { 'phone': phone, 'verifyCode': verifyCode, 'inviterId': inviterId, 'activityId': activityId }).then(() => {
            ShowToast({ content: '请登录橙子校园APP领取', time: 5000 });
        }).catch((err) => {
            console.log(err);
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    getReward(agentId:number, activityId:number) {
        get(`/user/marketing/user/register/reward/${ agentId }`).then((response: any) => {
            const { data } = response;
            this.setState({
                activityId: (data['activityId'] ?? activityId),
                reward: (data['reward'] ?? '0'),
                rewardType: (data['rewardType'] ?? 0),
            });
        }).catch((err) => {
            ShowToast({ content: err.msg || '请求错误' });
        });
    }

    render() {
        const { phone, verifyCode, tips, getCode, count, oldCount, reward, rewardType } = this.state;

        return (
            <div className="user_invite">
                <div className="user_invite_main">
                    <div className="user_invite_money">
                        { rewardType !== 2 &&
                        <span style={ { fontSize: '26px', fontWeight: 'bold' } }>{ rewardType === 3 ? '最低￥' : '￥' }</span> }
                        <span style={ { fontSize: '82px' } }>{ reward }</span>
                        { rewardType === 2 &&
                        <span style={ { fontSize: '26px', fontWeight: 'bold' } }>折起</span> }
                    </div>
                    <div className="user_invite_main_title">{ rewardType === 2 ? '新人专享折扣' : '新人专享红包' }</div>
                    <div className="user_invite_footer">
                        <div className="user_invite_input_box">
                            <Input
                                className="user_invite_input"
                                value={ phone }
                                maxLength={ 11 }
                                onChange={ this.onPhoneChange }
                                placeholder="请输入手机号"
                            />
                        </div>
                        <div className="user_invite_input_box">
                            <Input
                                className="user_invite_input"
                                value={ verifyCode }
                                maxLength={ 11 }
                                placeholder="请输入验证码"
                                onChange={ this.onCodeChange }
                            />
                            <span
                                onClick={ getCode ? this.getCode.bind(this) : undefined }
                                className={ `${ count === oldCount ? 'get-code-begin' : 'get-code-after' }` }
                            >{ tips }</span>
                        </div>

                        <div className="user_invite_button_image" onClick={ this.handleReceive.bind(this) }>
                            立即领取
                        </div>
                    </div>
                </div>
                <div className="toast_box">
                    <div id="toast" />
                </div>
            </div>
        );
    }
}

export default UserInvite;
